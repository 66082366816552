import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-service',
  templateUrl: './request-service.component.html',
  styleUrls: ['./request-service.component.scss']
})
export class RequestServiceComponent implements OnInit {

  requestSubmitted = false
  form = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      className: 'field-style',
      templateOptions: {
        required: true,
        label: 'Full Name',
        appearance: 'fill',
        placeholder: 'Enter your last name',
      },
    },
    {
      key: 'sender_email',
      type: 'input',
      className: 'field-style',
      templateOptions: {
        type: 'email',
        label: 'Email',
        required: true,
        appearance: 'fill',
        placeholder: 'Enter your email address',
      },
      validators: {
        validation: ['email']
      }
    },
    {
      key: 'pnone_number',
      type: 'input',
      className: 'field-style',
      templateOptions: {
        required: true,
        appearance: 'fill',
        label: 'Phone Number',
        placeholder: 'Enter your phone number',
      }
    },
    {
      key: 'address',
      type: 'input',
      className: 'field-style',
      templateOptions: {
        required: true,
        appearance: 'fill',
        label: 'Home adddress OR Location',
        placeholder: 'Enter the location to have the work done',
      }
    },
    {
      key: 'content',
      type: 'textarea',
      templateOptions: {
        rows: 12,
        appearance: 'fill',
        matTextareaAutosize: true,
        label: 'Additional details ',
        placeholder: 'Anything you would like for us to know',
      }
    }
  ];
  
  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.requestSubmitted = true;
    const body = {
      ...this.model,
      source: 'Yard Enginner',
      subject: 'Service Needed'
    };
    this.http.post(
      `${environment.emailService.protocol}://${environment.emailService.url}`,
      body, {
        params: {token: environment.emailService.token}
      }).toPromise().then(
      (res: any) => {
        this.router.navigate(['/service/request/confirmation'], {
          queryParams: {
            title: 'Thank you',
            subtitle: 'Your request has been sucessfully sent.',
            messages: [
              'A Yard Engineer representative will be in contact with you within 5 to 10 min.'
            ]
          }
        }).then(() => {});
      }
    ).catch(
      (error: any) => {
        this.requestSubmitted = true;
        return
      }
    );
  }

}
