<section fxLayout="column" fxLayoutAlign="center center" class="full-page-container">
    <mat-card class="confirmation-card mat-elevation-z10">
      <mat-card-header fxLayout='column' fxLayoutAlign="center center">
        <mat-card-title fxLayout="column" fxLayoutAlign="center center">
          <img mat-card-image src="../../../assets/logos/yardLogo.png" alt="yard engineer full logo">
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <h1>{{'Confirmation' | titlecase}}</h1>
        <h6>{{confirmationData.title}}</h6>
        <label>{{confirmationData.subtitle}}</label>
        <p *ngFor="let msg of confirmationData.messages">{{ msg }}</p>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <a [routerLink]="'/service/request'" class="return-btn">
            {{ 'return to service request' | uppercase}}
        </a>
      </mat-card-actions>
    </mat-card>
  </section>