<section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="full-page-container">
  <div class="loader-wrap" fxLayoutAlign="center center" *ngIf="requestSubmitted">
    <mat-spinner class="loader-spin"></mat-spinner>
  </div>
    <mat-card class="request-card mat-elevation-z10">
      <mat-card-header fxLayout='column' fxLayoutAlign="center center">
        <mat-card-title fxLayout="column" fxLayoutAlign="center center">
          <img mat-card-image src="../../../assets/logos/yardLogo.png" alt="yard engineer full logo">
        </mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayoutAlign="center center">
        <form [formGroup]="form">
          <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
        </form>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button type="submit" color="primary" [disabled]="form.invalid" mat-raised-button (click)="onSubmit()">Request service</button>
      </mat-card-actions>
    </mat-card>
  </section>