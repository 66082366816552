import {NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import {MatCardModule} from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import { FormlyMaterialModule } from '@ngx-formly/material';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormlyModule,
    MatCardModule,
    MatButtonModule,
    FlexLayoutModule,
    FormlyMaterialModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    FormlyModule,
    MatCardModule,
    MatButtonModule,
    FlexLayoutModule,
    FormlyMaterialModule,
    MatProgressSpinnerModule
  ],
})
export class YardMaterialsModule { }
