import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { ServiceModule } from './service/service.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { YardMaterialsModule } from './yard-materials/yard-materials.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { emailErrorMessage, emailValidator, requiredErrorMessage } from './app.error.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ServiceModule,
    AppRoutingModule,
    YardMaterialsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({ 
      extras: { lazyRender: true }, 
      validators: [
        { name: 'email', validation: emailValidator },
      ], 
      validationMessages: [
        { name: 'required', message: requiredErrorMessage }, 
        { name: 'email', message: emailErrorMessage },
      ] 
    }),
    FormlyMaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
