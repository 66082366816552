import { FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function requiredErrorMessage(err, field: FormlyFieldConfig): string {
    return `${field.templateOptions.label} is a required`;
  }

  export function emailValidator(control: FormControl): ValidationErrors {
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(control.value)) {
      return {email: true};
    }
  
    return null;
  }
  
  export function emailErrorMessage(err, field: FormlyFieldConfig): string {
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(field.formControl.value)) {
      return `${field.templateOptions.label} invalid.`;
    }
  
    return null;
  }