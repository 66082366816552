import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceRoutingModule } from './service-routing.module';
import { YardMaterialsModule } from '../yard-materials/yard-materials.module';
import { RequestServiceComponent } from './components/request-service/request-service.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';


@NgModule({
  declarations: [RequestServiceComponent, ConfirmationComponent],
  imports: [
    CommonModule,
    FormlyModule,
    HttpClientModule,
    ReactiveFormsModule,
    YardMaterialsModule,
    ServiceRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ServiceModule { }
