import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface ConfirmationObject {
  title?: string;
  subtitle?: string;
  messages: string[];
}

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  confirmationData: ConfirmationObject = null;

  constructor(private activedRouter: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.activedRouter.queryParams.subscribe((data: ConfirmationObject) => {
      if (Object.keys(data).length === 0) {
        this.router.navigate(['/service/request']).then(() => {});
        return;
      }
      this.confirmationData = data;
    });
  }

}
